const CHEMICALS_LIBRARY_STRINGS = {
  chemicalLibDlgHeading: "Chemical Library",
  projectCostDlgHeading: "Project Costs",
  chemicalLibDlgSubHeading: "Add chemicals as per your need",
  projectCostDlgSubHeading: "Edit prices of chemicals and operations",
  questionIconTooltip: "Help",
  saveChanges: "Save Changes",
  displayName: "Display Label",
  chemicalName: "Chemical",
  symbol: "Symbol",
  chemicalCat: "Category",
  bulkConcentration: "Concentration",
  bulkDensity: "Density",
  bulkPrice: "Price",
  costType: "Type",
  showDropDown: "View in all projects",
  actions: "Actions",
  loadingData: "Loading data",
  helpDialogTitle: "Chemical Library & Costs Help",
  helpDialogBackBtnTitle: "Back",
  addEditChemicals: "Add/Edit Chemicals",
  makePriceNewDefault: "Make these prices as new default",
};

export const CHEMICAL_LIBRARY_HELP_DATA = [
  {
    section: "New Chemicals",
    description: `Any new chemicals have to be created in the chemical library menu. Once added these new 
    chemicals can be seen in your project costs list. You can choose from the chemicals list that 
    WavePro has or you can create custom ones on your own.`,
  },
  {
    section: "Changing Operation Costs",
    description: `The costs of these chemicals in WavePro are based on the values that can be generic to the 
    water business. We advise you to change these costs inside the project rather changing in the 
    chemical library. Changing the prices in chemical library doesn’t affect/change the prices in 
    your project which is already using these chemicals.`,
  },
  {
    section: "Editing Chemical Concentration/Density",
    description: `We generally advise you not to change concentration very frequently. You can create any 
    number of concentrations for a given chemical. You can also have different prices for a chemical 
    in different projects since costs are specific to individual project.`,
  },
  {
    section: "Deleting a chemical",
    description: `Any chemical that is already present in multiple projects can’t be deleted, you are advised 
    to review the projects where it’s used and change it to something else before deleting an chemical 
    that is already used in existing projects.`,
  },
];

export default CHEMICALS_LIBRARY_STRINGS;
