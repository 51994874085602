import React from "react";
import PropTypes from "prop-types";

import InputWithText from "@common/styles/components/inputs/InputWithText";

const ChemicalProjectCostRenderer = ({ data }) => (
    <div className="d-flex align-content-center">
        <InputWithText inputText={"$"} value={data.bulkPrice} />
    </div>
);

ChemicalProjectCostRenderer.propTypes = {
    projectCost: PropTypes.number.isRequired,
};

export default ChemicalProjectCostRenderer;
