import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES } from "@constants/units.constant";

export const WPUfPressureRatingsTableColumns = [
  {
    field: "label", // custom field not present in the API response
    rowHeader: true,
    headerName: reportStrings.process,
  },
  {
    field: "t",
    headerName: reportStrings.t,
  },
  {
    field: "tmPa",
    headerName: (
      <>
        {reportStrings.tmp}
        <sup>a</sup>
      </>
    ),
  },
  {
    field: "foulingMaxDP",
    headerName: reportStrings.foulingMaxDp,
  },
  {
    field: "pipingDP",
    headerName: reportStrings.pipingDp,
  },
  {
    field: "filtratePressure",
    headerName: reportStrings.filtratePressure,
  },
  {
    field: "feedPressureb",
    headerName: (
      <>
        {reportStrings.feedPressure}
        <sup>b</sup>
      </>
    ),
  },
  {
    field: "pressureRating",
    headerName: reportStrings.pressureRating,
  },
  {
    field: "oKc",
    headerName: (
      <>
        {reportStrings.ok}?<sup>c</sup>
      </>
    ),
  },
];

export const WPUfPressureRatingsTableRowsData = {
  // custom row not present in the API response
  filtration: {
    label: reportStrings.filtration,
  },
  filtration_Min_Temp: {
    label: reportStrings.minTemp,
    indentLevel: 1,
  },
  filtration_Design_Temp: {
    label: reportStrings.designTemp,
    indentLevel: 1,
  },
  filtration_Max_Temp: {
    label: reportStrings.maxTemp,
    indentLevel: 1,
  },
  bw: {
    label: reportStrings.bw,
  },
  ceb: {
    label: reportStrings.ceb,
  },
  mini_CIP: {
    label: reportStrings.miniCip,
  },
  ciP: {
    label: reportStrings.cip,
  },
};

export const WPUfPressureRatingsTableUnitMapping = {
  t: UNIT_TYPES.TEMPERATURE,
  tmPa: UNIT_TYPES.PRESSURE,
  foulingMaxDP: UNIT_TYPES.PRESSURE,
  pipingDP: UNIT_TYPES.PRESSURE,
  filtratePressure: UNIT_TYPES.PRESSURE,
  feedPressureb: UNIT_TYPES.PRESSURE,
  pressureRating: UNIT_TYPES.PRESSURE,
  oKc: "",
};
