import { useMemo } from "react";

import CustomModal from "@components/CustomModal";
import WPButton from "@components/WPButton";

import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import useChemicalLibrary from "../ChemicalLIbraryHooks/useChemicalLibrary";
import ProjectCostTable from "../ProjectCostTable";

import "./index.scss";

const FooterBtns = [
    {
        id: "makeDefaultPriceBtn",
        variant: "text",
        label: CHEMICALS_LIBRARY_STRINGS.makePriceNewDefault,
    },
    {
        id: "addEditChemicalBtn",
        variant: "text",
        label: CHEMICALS_LIBRARY_STRINGS.addEditChemicals,
    },
    {
        id: "addEditChemicalBtn",
        label: CHEMICALS_LIBRARY_STRINGS.saveChanges,
        disabled: true
    }
];

const ProjectCostDialog = ({ onClose }) => {
    const { chemicals, isLoading } = useChemicalLibrary();

    //TODO needs to do btn click handling
    const handleBtnClick = ({ target }) => console.log("Btn Clicked: ", target.id);

    const modalFooter = useMemo(() => FooterBtns.map((btnConfig => (
        <WPButton
            key={btnConfig.id}
            variant={btnConfig.variant}
            id={btnConfig.id}
            onClick={handleBtnClick}
            disabled={btnConfig.disabled}
        >
            {btnConfig.label}
        </WPButton>
    ))
    ), []);

    return (
        <CustomModal
            header={CHEMICALS_LIBRARY_STRINGS.projectCostDlgHeading}
            headerHelper={CHEMICALS_LIBRARY_STRINGS.projectCostDlgSubHeading}
            onModalClose={onClose}
            width="1024px"
            customclass="checmical--dialog-container"
            isLoading={isLoading}
            loadingText={CHEMICALS_LIBRARY_STRINGS.loadingData}
            modalFooter={modalFooter}
        >
            <ProjectCostTable chemicals={chemicals} />
        </CustomModal>
    );
};

export default ProjectCostDialog;