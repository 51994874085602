import React, { memo } from "react";
import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";

import "./WPAgGrid.scss";

const WPAgGrid = ({ rowData, columnDefs, tableHeight }) => (
    <div className="ag-theme-alpine wp-ag-grid" style={{ height: tableHeight }}>
        <AgGridReact rowData={rowData} columnDefs={columnDefs} />
    </div>
);

WPAgGrid.propTypes = {
    rowData: PropTypes.array,
    columnDefs: PropTypes.array,
    tableHeight: PropTypes.string,
};

WPAgGrid.defaultProps = {
    rowData: [],
    columnDefs: [],
    tableHeight: "300px",
};

export default memo(WPAgGrid);