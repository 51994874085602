import { UNIT_TYPES } from "@constants/units.constant";

import { useReportUtils } from "@features/report-new/useReportUtils";

import {
  WPUfSystemOverviewRows,
  WPUfSystemOverviewTableLabels,
  WPUfSystemOverviewValueLabels as valueLabels,
} from "./WPUfSystemOverViewTable.constants";

export const useWPUfSystemOverViewTableData = () => {
  const { ufReportRespData, formatLabelValue, formatKeyValue, formatUnitLabel, formatValue } = useReportUtils();

  const convertToSystemOverviewTableData = () => {
    const { uf_system_overview } = ufReportRespData;
    const {
      module_type,
      units_one: { online, standby, redundant } = {},
      modules: { per_unit, total } = {},
      system_flow_rate: { gross_feed: systemFlowGrossFeed, net_product: systemFlowNetProduct } = {},
      unit_flow_rate: { gross_feed: unitFlowGrossFeed, net_product: unitFlowNetProduct } = {},
      system_recovery,
      utility_water = {},
    } = uf_system_overview || {};

    const valueRows = [
      { col3: module_type },
      {
        col3: formatLabelValue(valueLabels.online, online),
        col4: formatLabelValue(valueLabels.standBy, standby),
      },
      {
        col4: formatLabelValue(valueLabels.redundant, redundant),
      },
      {
        col3: formatLabelValue(valueLabels.perUnit, per_unit),
        col4: formatLabelValue(valueLabels.total, total),
      },
      {
        col3: formatLabelValue(valueLabels.grossFeed, systemFlowGrossFeed, UNIT_TYPES.FLOW),
        col4: formatLabelValue(valueLabels.netProduct, systemFlowNetProduct, UNIT_TYPES.FLOW),
      },
      {
        col3: formatLabelValue(valueLabels.grossFeed, unitFlowGrossFeed, UNIT_TYPES.FLOW),
        col4: formatLabelValue(valueLabels.netProduct, unitFlowNetProduct, UNIT_TYPES.FLOW),
      },
      {
        col3: formatValue(system_recovery * 100),
      },
      {}, // tmp row to be added
      {
        col3: formatKeyValue(valueLabels.forwardFlush, utility_water?.forward_flush),
        col4: formatKeyValue(valueLabels.backwash, utility_water?.backwash),
      },
      {
        col3: formatKeyValue(valueLabels.ceb, utility_water?.ceb),
        col4: formatKeyValue(valueLabels.cip, utility_water?.cip),
      },
    ];

    const data = WPUfSystemOverviewRows.map((row, index) => {
      const singleRowData = valueRows[index];
      singleRowData.col1 = WPUfSystemOverviewTableLabels[row[0]];
      singleRowData.col2 = formatUnitLabel(row[1]);
      return singleRowData;
    });

    return data;
  };

  return { convertToSystemOverviewTableData };
};
