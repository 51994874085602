import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES, UNITS } from "@constants/units.constant";

export const WPUfOperatingConditionsTableColumns = [
  {
    field: "col1",
    rowHeader: true,
  },
  {
    field: "col2",
    headerName: reportStrings.duration,
  },
  {
    field: "col3",
    headerName: reportStrings.interval,
  },
  {
    field: "col4",
    headerName: reportStrings.flux,
  },
  {
    field: "col5",
    headerName: reportStrings.flow,
  },
];

export const WPUfOperatingConditionsTableRowsData = {
  filtration: {
    label: reportStrings.filtration,
  },
  instantaneous: {
    label: reportStrings.instantaneous,
    indentLevel: 1,
  },
  units_ins_one: {
    label: reportStrings.trains,
    indentLevel: 2,
  },
  units_ins_two: {
    label: reportStrings.trains,
    indentLevel: 2,
  },
  average: {
    label: reportStrings.average,
    indentLevel: 1,
  },
  net: {
    label: reportStrings.net,
    indentLevel: 1,
  },
  backwash_op: {
    label: reportStrings.backwash,
  },
  acid_CEB: {
    label: reportStrings.acidCEB,
    unit: {
      interval: UNITS.hour,
    },
  },
  alkali_CEB: {
    label: reportStrings.alkaliCEB,
    unit: {
      interval: UNITS.hour,
    },
  },
  disinfection_CEB: {
    label: reportStrings.disinfectionCEB,
    unit: {
      interval: UNITS.hour,
    },
  },
  mini_CIP_op: {
    label: reportStrings.miniCip,
    unit: {
      interval: UNITS.day,
    },
  },
  ciP_op: {
    label: reportStrings.cip,
    unit: {
      interval: UNITS.day,
    },
  },
  membrance_integrity_testing: {
    label: reportStrings.membraneIntegrationTesting,
    unit: {
      interval: UNITS.hour,
    },
  },
};

export const WPUfOperatingConditionsTableUnitMapping = {
  duration: UNITS.min,
  interval: UNITS.min,
  flux: UNIT_TYPES.FLUX,
  flow: UNIT_TYPES.FLOW,
};
