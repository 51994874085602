import { useReportUtils } from "@features/report-new/useReportUtils";

import { WPUfDesignWarningsRowsData as rowsData } from "./WPUfDesignWarningsTable.constants";

export const useWPUfDesignWarningsTableData = () => {
  const { ufReportRespData, formatUnitLabel, formatConvertValue } = useReportUtils();
  const { design_warning } = ufReportRespData?.uf_design_quality || {};

  const convertToUfDesignWarningsTableData = () => {
    const data = Object.entries(rowsData).reduce((acc, curr) => {
      const [key, row] = curr;
      const { limit, estimate, display } = design_warning[key] || {};
      if (!display) return acc;
      acc.push({
        col1: row.label,
        col2: formatUnitLabel(row.unit, row.subUnitText),
        col3: formatConvertValue(limit, row.unit),
        col4: formatConvertValue(estimate, row.unit),
      });
      return acc;
    }, []);

    return data;
  };

  return { convertToUfDesignWarningsTableData };
};
