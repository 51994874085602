import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRequest } from "src/api/baseApi";

import API_URLS from "@constants/api.urls";

const useChemicalLibrary = () => {
  const [chemicals, setChemicals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { UserId } = useSelector(state => state.userInfo.data) || {};

  const fetchChemicals = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await getRequest(API_URLS.chemicalLibrary, { userid: UserId });
      setChemicals(data?.lstChemicalVMs ?? []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [UserId]);

  useEffect(() => {
    if (UserId) {
      fetchChemicals();
    }
  }, [UserId, fetchChemicals]);

  return { chemicals, isLoading };
};

export default useChemicalLibrary;
