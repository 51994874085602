import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import {
  ChemicalDisplayNameRenderer,
  ChemicalDropdownRenderer,
  ChemicalProjectCostRenderer,
  ChemicalSymbolRenderer,
} from "../ChemicalTableRenderer";

export const PROJECT_COST_TABLE_CONFIG = [
  {
    id: 1,
    headerName: CHEMICALS_LIBRARY_STRINGS.displayName,
    field: "displayName",
    cellRenderer: ChemicalDisplayNameRenderer,
    width: 150,
  },
  { id: 2, headerName: CHEMICALS_LIBRARY_STRINGS.chemicalName, field: "chemicalName", width: 180 },
  {
    id: 3,
    headerName: CHEMICALS_LIBRARY_STRINGS.symbol,
    field: "symbol",
    cellRenderer: ChemicalSymbolRenderer,
    width: 100,
  },
  { id: 5, headerName: CHEMICALS_LIBRARY_STRINGS.chemicalCat, field: "chemicalCat", width: 120 },
  { id: 4, headerName: CHEMICALS_LIBRARY_STRINGS.bulkConcentration, field: "bulkConcentration", width: 115 },
  {
    id: 6,
    headerName: CHEMICALS_LIBRARY_STRINGS.bulkDensity,
    field: "bulkDensity",
    width: 100,
  },
  {
    id: 7,
    headerName: CHEMICALS_LIBRARY_STRINGS.bulkPrice,
    field: "bulkPrice",
    width: 125,
    cellRenderer: ChemicalProjectCostRenderer,
  },
  {
    id: 8,
    headerName: CHEMICALS_LIBRARY_STRINGS.costType,
    field: "costType",
    width: 120,
    cellRenderer: ChemicalDropdownRenderer,
  },
];
