import React from "react";
import PropTypes from "prop-types";

import { WPTypogrpahy } from "@components/WPTypography";

import { WPHeadingSize, WPHeadingSizeFontMapping, WPHeadingTypes } from "./WPHeading.constant";

/**
 * WPHeading component
 *
 * @param {Object} props - Component props
 * @param {'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge'} props.size - Size of the heading
 * @param {'default' | 'primary'} props.type - Type of the heading
 * @param {string} props.text - Text content of the heading
 * @param {React.ReactNode} props.children - Child elements
 * @param {string} [props.color] - Custom color for the heading
 * @param {boolean} [props.secondary] - Whether to use secondary font weight
 */
const WPHeading = ({ size, type, text, children, color, secondary, className }) => (
    <WPTypogrpahy
        variant={size}
        text={text}
        color={color || WPHeadingTypes[type]}
        asElement={WPHeadingSize[size]}
        className={`${className} ${WPHeadingSizeFontMapping[size]}`}
        fontFamily="Diodrum"
        fontWeight={secondary ? "400" : "600"}
    >
        {children}
    </WPTypogrpahy>
);

WPHeading.propTypes = {
    size: PropTypes.oneOf(["xsSmall", "small", "medium", "large", "extraLarge"]),
    type: PropTypes.oneOf(["default", "primary"]),
    text: PropTypes.string,
    children: PropTypes.node,
    color: PropTypes.string,
    secondary: PropTypes.bool,
    className: PropTypes.string,
};

WPHeading.defaultProps = {
    size: "medium",
    type: "primary",
    text: "",
    children: null,
    color: null,
    secondary: false,
    className: ""
};

export default WPHeading;