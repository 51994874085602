import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { typographyVariants, WPTypogrpahy } from "@components/WPTypography";

import { colors } from "@common/styles/Theme";

import { WPReportSectionHeader } from "@features/report-new/common/WPReportSectionHeader";
import { WPReportTableNotes } from "@features/report-new/common/WPReportTableNotes";

const Table = styled.table`
  border: ${props => (props.fullBorder ? `1px solid ${colors.GreyE1};` : "0px;")};
  width: 100%;
`;

const TableHead = styled.thead`
  visibility: ${props => (props.showHeader ? "initial" : "collapse")};
`;

const TableRow = styled.tr``;

const TableHeader = styled.th`
  text-align: left;
  padding: 8px;
  border: 1px solid ${colors.GreyE1};
  width: ${props => (props.customWidth ? props.customWidth : "auto")};
  :first-child {
    border-left: none;
  }
  :last-child {
    border-right: none;
  }
`;

const TableCell = styled.td`
  border: 1px solid ${colors.GreyE1};
  background-color: ${props => (props.rowHeader ? colors.Pale20PaleTealOp50 : colors.White)};
  vertical-align: middle;
  padding: 8px;
  :first-child {
    border-left: none;
  }
  :last-child {
    border-right: none;
  }
`;

// This is a view only table component
export const WPTable = ({ columns, rows, fullBorder, showHeader, sectionHeader, footNotesList }) => (
  <>
    {sectionHeader && <WPReportSectionHeader text={sectionHeader} />}
    <Table fullBorder={fullBorder}>
      <TableHead showHeader={showHeader}>
        <TableRow>
          {columns.map((column, index) => {
            if (column.colSpan === 0) {
              return <></>;
            }
            const { headerName, colSpan, width } = column;
            return (
              <TableHeader key={`header-${index}`} colSpan={colSpan} customWidth={width}>
                <WPTypogrpahy variant={typographyVariants.tableColumnData}>{headerName}</WPTypogrpahy>
              </TableHeader>
            );
          })}
        </TableRow>
      </TableHead>
      <tbody>
        {rows?.map((row, rowIndex) => (
          <TableRow key={`row-${rowIndex}`}>
            {columns.map((column, colIndex) => {
              const { onCell, renderCell } = column;
              const { rowSpan, colSpan } = onCell?.(row, rowIndex) || {};
              if (colSpan === 0 || rowSpan === 0) {
                return <></>;
              }
              return (
                <TableCell
                  key={`cell-${rowIndex}${colIndex}`}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  rowHeader={column.rowHeader}
                >
                  {renderCell ? (
                    renderCell(row[column.field], row, rowIndex)
                  ) : (
                    <WPTypogrpahy variant={typographyVariants.tableData}>
                      {row[column.field]?.value || row[column.field]}
                    </WPTypogrpahy>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </tbody>
    </Table>
    {footNotesList && <WPReportTableNotes list={footNotesList} />}
  </>
);

WPTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      headerName: PropTypes.string,
      field: PropTypes.string.isRequired,
      colSpan: PropTypes.number,
      rowHeader: PropTypes.bool,
      onCell: PropTypes.func,
      /**
       * Function to render custom cell content.
       * @param {any} value - The value of the cell.
       * @param {object} row - The entire row data.
       * @param {number} rowIndex - The index of the row.
       * @returns {React.ReactNode} - The custom cell content.
       */
      renderCell: PropTypes.func,
      width: PropTypes.string,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  fullBorder: PropTypes.bool,
  showHeader: PropTypes.bool,
};

WPTable.defaultProps = {
  fullBorder: false,
  showHeader: true,
};
