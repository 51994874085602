import React from "react";

import CustomSelect from "@common/styles/components/selects/CustomSelect";

const options = ["gal", "lb", "KG", "L"];

const ChemicalDropdownRenderer = ({ value }) => {
    const handleDropdownChange = data => console.log("handleDropdownChange: ", data);

    return (
        <CustomSelect id="id" value={value} name="name" onChange={handleDropdownChange}>
            {options.map(item => (
                <option value={item} key={item}>
                    {item}
                </option>
            ))}
        </CustomSelect>
    );
};

export default ChemicalDropdownRenderer;