import { useDispatch } from "react-redux";

import { setUfReportTableData } from "@common/ReportSlice";

import { useReportUtils } from "../useReportUtils";

import { useWPUfOperatingConditionsTableData } from "./WPUfOperatingConditionsTable/useWPUfOperatingConditionsTableData";
import { useWPUfConfigurationOptionsTableData } from "./WPUfConfiguringationOptionsTable";
import { useWPUfDesignWarningsTableData } from "./WPUfDesignWarningsTable";
import { useWPUfFlowDetailsTableData } from "./WPUfFlowDetailsTable";
import { useWPUfPressureRatingsTableData } from "./WPUfPressureRatingsTable";
import { useWPUfSystemOverViewTableData } from "./WPUfSystemOverviewTable";
import { useWPUfWaterQualityTableData } from "./WPUfWaterQualityTable";

export const useUfReport = () => {
  const dispatch = useDispatch();

  const { ufReportRespData } = useReportUtils();

  const { convertToSystemOverviewTableData } = useWPUfSystemOverViewTableData();
  const { convertToUfConfigurationOptionsTableData } = useWPUfConfigurationOptionsTableData();
  const { convertToUfDesignWarningsTableData } = useWPUfDesignWarningsTableData();
  const { convertToUfFlowDetailsTableData } = useWPUfFlowDetailsTableData();
  const { convertToUfOperatingConditionsTableData } = useWPUfOperatingConditionsTableData();
  const { convertToUfWaterQualityTableData } = useWPUfWaterQualityTableData();
  const { convertToUfPressureRatingsTableData } = useWPUfPressureRatingsTableData();

  const convertUfCalcReportToTableData = () => {
    if (!ufReportRespData) return;
    dispatch(
      setUfReportTableData({
        uf_system_overview: convertToSystemOverviewTableData(),
        uf_configuration_options: convertToUfConfigurationOptionsTableData(),
        uf_design_quality: convertToUfDesignWarningsTableData(),
        flow_details: convertToUfFlowDetailsTableData(),
        uf_operating_conditions: convertToUfOperatingConditionsTableData(),
        uf_water_quality: convertToUfWaterQualityTableData(),
        uf_Power_ratings: convertToUfPressureRatingsTableData(),
      }),
    );
  };

  return { convertUfCalcReportToTableData };
};
